import { useEffect, useState } from "react";

/**
 * @function useFocusTrapping restricts keyboard focus to elements within a given element
 * @param {RefObject} ref restricts keyboard focus on this UI element's focusable children
 * @return {null}
 */
export function useFocusTrapping(ref, deps) {
  const [focusedElementIdx, setFocusedElementIdx] = useState(null);
  useEffect(
    () => {
      const parentElement = ref.current;
      const focusableElements =
        parentElement?.querySelectorAll?.(
          'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
        ) || [];
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (focusedElementIdx === null) {
        firstElement?.focus();
        setFocusedElementIdx(0);
      }

      const handleTabKey = (e) => {
        e.preventDefault();

        /**
         * variables contstrained to the first and last focusable elements within a modal
         */
        const incrementIdx =
          focusedElementIdx < focusableElements.length - 1
            ? focusedElementIdx + 1
            : focusableElements.length - 1;
        const decrementIdx =
          focusedElementIdx === 0 ? focusableElements.length - 1 : focusedElementIdx - 1;

        /**
         * logic to handle moving the focus "forward"/down the DOM
         */
        if (!e.shiftKey && document.activeElement !== lastElement) {
          setFocusedElementIdx(incrementIdx);
          focusableElements[incrementIdx]?.focus();
        } else if (!e.shiftKey && document.activeElement === lastElement) {
          setFocusedElementIdx(0);
          firstElement.focus();
        }

        /**
         * logic to handle moving the focus "backward"/up the DOM
         */
        if (e.shiftKey && document.activeElement !== firstElement) {
          setFocusedElementIdx(decrementIdx);
          focusableElements[decrementIdx]?.focus();
        } else if (e.shiftKey && document.activeElement === firstElement) {
          setFocusedElementIdx(focusableElements.length - 1);
          lastElement.focus();
        }
      };

      /**
       * if we need to use this hook for closable, non-paywall modals in the future,
       * we can add an `onModalClose` handler above.
       */
      const keyListenersMap = new Map([
        // [27, onModalClose],
        [9, handleTabKey],
      ]);

      function keyListener(e) {
        const listener = keyListenersMap.get(e.keyCode);
        return listener && listener(e);
      }

      document.addEventListener("keydown", keyListener);

      // cleanup
      return () => document.removeEventListener("keydown", keyListener);
    },
    deps ? [ref, ...deps] : undefined
  );
}
