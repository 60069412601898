import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "~features/auth";
import { pushDataLayer } from "~lib/analytics/datalayer";
import { cx } from "~utils";
import { emailCollectorCookie } from "./constants";
import { useFocusTrapping } from "./useFocusTrapping";

import styles from "./emailCollectorModal.module.css";

/*
 * setShowEmailCollectorModal is a useState function prop sent from the PaywallFlow component.
 * This function sets a boolean value that the PaywallFlow component uses to determine whether
 * to render the EmailCollectorModal component.
 */
export default function EmailCollectorModal({
  setShowEmailCollectorModal,
  setReadyToInject,
  meterCount,
}) {
  const { login, user } = useAuth();

  /* https://react.dev/reference/react/useRef#manipulating-the-dom-with-a-ref
   * In the HTML for the form later in this file, there is a ref attribute on the
   * <form> element.  In React, we can reference the form DOM using useRef().
   * The null argument is because we don't need an initial value.
   */
  const formRef = useRef(null);

  let [showInvalidEmailErrMsg, setShowInvalidEmailErrMsg] = useState(false);
  let [showEmailExistsErrMsg, setShowEmailExistsErrMsg] = useState(false);
  let [showOtherErrMsg, setOtherErrMsg] = useState(false);
  let [formErr, setFormErr] = useState(false);
  let [formSubmitting, setFormSubmitting] = useState(false);

  const emailCollectorModalOverlayRef = useRef(null);
  useFocusTrapping(emailCollectorModalOverlayRef);

  /* Datalayer logging when the modal opens up */
  useEffect(() => {
    pushDataLayer({
      event: "modalOpened",
      modalName: "email_collector",
      user: {
        meteredPaywallArticleNum: meterCount,
      },
    });
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    submitEmailCollectorForm();
  };

  /* Reset error states */
  const handleResetErrors = () => {
    /* Reset error states */
    setShowInvalidEmailErrMsg(false);
    setShowEmailExistsErrMsg(false);
    setOtherErrMsg(false);
    setFormErr(false);
    setFormSubmitting(false);
  };

  const submitEmailCollectorForm = async () => {
    /* https://react.dev/reference/react/useRef#manipulating-the-dom-with-a-ref
     * Once we have a ref of the HTML DOM, we can point directly to it by using the
     * current property on the ref object returned by useRef()
     */
    const formData = new FormData(formRef.current);
    const body = new URLSearchParams(formData);
    const SA_API_HOST = import.meta.env.PUBLIC_SA_API_HOST || "";

    const res = fetch(`${SA_API_HOST}/platform/api/v2/email-collector/`, {
      method: "POST",
      body: body,
      include: "include",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    /* Reset error states */
    handleResetErrors();

    /* Disable submit button during submission */
    setFormSubmitting(true);

    let response, resBody;

    try {
      /* Both res and .json() are async, so we need to await them and get
       * get the response before we actually examine the response.
       *
       * If any system errors happen (i.e. 404, 500), it will be caught here.
       */
      response = await res;
      resBody = await response.json();
    } catch (error) {
      setOtherErrMsg(true);
    }

    // @hack for development and proxies
    // When working across domains, cookies may not be set by the server
    let isCrossOrigin = new URL(response.url).host !== new URL(window.location).host;

    if (resBody.success && isCrossOrigin && !Cookies.get(emailCollectorCookie)) {
      Cookies.set(emailCollectorCookie, "1", { expires: 365 });
    }

    /* Business errors are not thrown as system errors (i.e. 404, 500),
     * so the request "succeeds" but we check the response to see if the
     * success property is true or false.  If the success property is false,
     * we have a business error.
     */
    if (resBody.success === false) {
      setFormErr(true);

      if (resBody.error === "INVALID_EMAIL") {
        setShowInvalidEmailErrMsg(true);
      } else if (resBody.error === "EMAIL_EXISTS") {
        setShowEmailExistsErrMsg(true);
      } else {
        setOtherErrMsg(true);
      }
    } else {
      /* Datalayer logging when email successfully submitted */
      pushDataLayer({
        event: "emailObtainedFromCollectorModal",
        user: {
          newsletters: formData.getAll("lists"),
        },
      });

      /* Tell the PaywallFlow component to hide the email collector modal */
      setShowEmailCollectorModal(false);

      // The modal is gone, we can inject ads
      setReadyToInject(true);
    }
  };

  return (
    <>
      <div
        id="emailCollectorModalOverlay"
        className={cx(styles.emailCollectorModalOverlay)}
        ref={emailCollectorModalOverlayRef}
      >
        <section className={styles.emailCollectorModalWrap}>
          <div className={cx(styles.emailCollectorModalHdrWrap, user ? styles.isCentered : "")}>
            {!user && (
              <div>
                <p className={styles.emailCollectorModalHdrTxt}>
                  Already a subscriber?{" "}
                  <a
                    href="/account/"
                    id="emailCollectorModalSignInLnk"
                    className={styles.emailCollectorModalSignInLnk}
                    onClick={(e) => {
                      e.preventDefault();
                      login();
                    }}
                  >
                    Sign in
                  </a>
                </p>
              </div>
            )}
            <div>
              <p className={styles.emailCollectorModalHdrTxt}>
                Only get us in print?{" "}
                <a
                  href="/account/link/"
                  id="emailCollectorModalConnectLnk"
                  className={styles.emailCollectorModalConnectLnk}
                >
                  Connect your account
                </a>
              </p>
            </div>
          </div>
          <section className={styles.emailCollectorModalFormWrap}>
            <p className={styles.emailCollectorModalMainTxt}>
              Join the Scientific American Community
            </p>
            <p className={styles.emailCollectorModalSubTxt}>
              Get one more article for free by signing up for our newsletter.
            </p>
            <form
              id="emailCollectorForm"
              className={cx(
                styles.emailCollectorForm,
                formErr ? styles.emailCollectorError : false,
              )}
              onSubmit={handleFormSubmit}
              ref={formRef}
            >
              <label
                htmlFor="emailCollectorField"
                id="emailCollectorAddrLbl"
                className={styles.emailCollectorLbl}
              >
                Email Address
              </label>
              <input
                type="text"
                name="email"
                id="email"
                className={styles.emailCollectorField}
                required
                onFocus={handleResetErrors}
              />
              <p
                id="emailCollectorInvalidEmailErr"
                className={cx(
                  styles.emailCollectorInvalidEmailErr,
                  showInvalidEmailErrMsg ? true : styles.hidden,
                )}
              >
                Please enter a valid email address.
              </p>
              <p
                id="emailCollectorEmailExistsErr"
                className={cx(
                  styles.emailCollectorEmailExistsErr,
                  showEmailExistsErrMsg ? true : styles.hidden,
                )}
              >
                Email already exists. Enter a different email or{" "}
                <a
                  href="/account/"
                  id="emailCollectorModalErrSignInLnk"
                  className={styles.emailCollectorModalSignInLnk}
                  onClick={(e) => {
                    e.preventDefault();
                    login();
                  }}
                >
                  Sign In.
                </a>
              </p>
              <p
                id="emailCollectorErr"
                className={cx(styles.emailCollectorErr, showOtherErrMsg ? true : styles.hidden)}
              >
                There was a problem submitting your email. Please try again.
              </p>
              <input id="today_in_science" name="lists" type="hidden" value="today_in_science" />
              <input
                id="internal_marketing_opt_in"
                name="lists"
                type="hidden"
                value="internal_marketing_opt_in"
              />
              <button
                type="submit"
                id="emailCollectorContinueBtn"
                className={styles.emailCollectorContinueBtn}
                disabled={formErr || formSubmitting ? true : false}
              >
                <span className={styles.emailCollectorContinueBtnTxt}>Continue Reading</span>
              </button>
            </form>
            <p className={styles.emailCollectorModalFinePrint}>
              By giving us your email, you are agreeing to receive the Today In Science newsletter,
              emails regarding special offers from Scientific American, and to our{" "}
              <a
                href="https://www.scientificamerican.com/page/terms-of-use/"
                target="_blank"
                className={styles.emailCollectorModalTermsLnk}
              >
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                href="https://www.scientificamerican.com/page/privacy-policy/"
                target="_blank"
                className={styles.emailCollectorModalPrivacyLnk}
              >
                Privacy Policy
              </a>
              .
            </p>
          </section>
          <section className={styles.emailCollectorModalOffersWrap}>
            <span className={styles.emailCollectorModalOffersFinePrint}>
              Want to become a subscriber?
            </span>{" "}
            <a
              href="https://www.scientificamerican.com/getsciam/"
              target="_blank"
              className={styles.emailCollectorModalOffersLnk}
            >
              See all offers
            </a>
          </section>
        </section>
      </div>
    </>
  );
}
