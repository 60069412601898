import { useState } from "react";

import { usePageData } from "~core/hooks/use-page";
import DefaultLayout from "~ui/DefaultLayout/DefaultLayout";

import Article from "~/features/article/Article";
import { BlockProvider, standardBlocks } from "~/features/blocks";
import PaywallFlow from "~/features/paywall/PaywallFlow";
import { RecommendationsProvider } from "~/features/recommendations/ArticleRecommendations";
// this component can be used if ever marketing wants to run another survey in future
// import SurveyToast from "~ui/SurveyToast/SurveyToast";

export default function ArticlePage() {
  const { article, persistentHeaderTitle, readTime, wordCount, podcastSeriesInfo } = usePageData();
  // We can't inject ads until we know we're not going
  // To show the paywall. This state needs to be shared across
  // the tree.
  // @TODO: Move this into Overlay context so we can use useOverlay() instead of prop drilling
  // Should also be renamed to something less article-specific e.g., `mightShowOverlay`
  const [readyToInject, setReadyToInject] = useState(false);

  return (
    <RecommendationsProvider>
      <PaywallFlow setReadyToInject={setReadyToInject}>
        <DefaultLayout data={article} persistentHeaderTitle={persistentHeaderTitle}>
          {/* Give articles access to all standard blocks via useBlockComponents */}
          <BlockProvider components={standardBlocks}>
            <Article
              article={article}
              readTime={readTime}
              wordCount={wordCount}
              inject={readyToInject}
              podcastSeriesInfo={podcastSeriesInfo}
            />
            {/* <SurveyToast renderedIn={"article"} /> */}
          </BlockProvider>
        </DefaultLayout>
      </PaywallFlow>
    </RecommendationsProvider>
  );
}
